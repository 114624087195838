import {Query} from '@directus/types'
import {consola} from 'consola'
import arrayToTree from 'array-to-tree'
import {useAnalysisUtils} from './useAnalysisUtils'
import {AnalysisGroupTypeDto} from './analysis.type'
import {Logger} from '../../../utils/logger.utils'

export const useAnalysis = () => {
  const {getItems} = useDirectusItems()
  const {generateAnalysisGroupDto, parseAnalysisGroup, logger} = useAnalysisUtils(inject('globalSettings'))

  const getAnalysisGroups = async (languageCode: string) => {
    return getAnalysisGroupsEntity()
      .then((analysis_groups) => (
        {
          analysis_group: Array.isArray(analysis_groups)
            ? (arrayToTree(
              parseAnalysisGroup(analysis_groups.map((item: any) => generateAnalysisGroupDto(item, languageCode))),
              {
                parentProperty: 'parent',
                childrenProperty: 'children'
              }
            ))[0]
            : [],
        }))
      .catch((e) => {
        logger.error('ERR getAnalysisGroups: ', e);
        return []
      })
  }

  const getAnalysisGroupsForFilter = async (languageCode: string) => {
    return getAnalysisGroupsEntity()
      .then((analysis_groups) => (
        {
          analysis_group: Array.isArray(analysis_groups)
            ?
            parseAnalysisGroup(analysis_groups.map((item: any) => generateAnalysisGroupDto(item, languageCode)))
            : [],


        }))
      .catch((e) => {
        logger.error('ERR getAnalysisGroups : ', e)
        return []
      })
  }


  // }

  //===============================================
  const getAnalysisGroupsEntity = async (options?: {
    id?: string | number
    query?: Query
  }): Promise<Array<AnalysisGroupTypeDto>> => {
    let data: any = {
      collection: 'analysis_groups',
      params: {
        fields: [
          // "*",
          "id",
          "status",
          "parent",
          "translations.*",
          "children",
          "questions.id",
          "questions.analysis_id.*",
          "questions.analysis_id.id",
          "questions.analysis_id.type",
          "questions.analysis_id.analysis_group",
          "questions.analysis_id.icon",
          "questions.analysis_id.size",
          "questions.analysis_id.interface",
          "questions.analysis_id.field_name",
          "questions.analysis_id.title_size",
          "questions.analysis_id.layout",
          "questions.analysis_id.translations.*",
          "questions.analysis_id.answers.id",
          "questions.analysis_id.answers.related_analysis_id.*",
          "questions.analysis_id.answers.related_analysis_id.translations.*",
        ],
        filter: {
          _and: [
            {
              status: {
                _eq: "published"
              }
            },
          ]
        },
      }
    }
    if (options?.query) {
      data.params = {
        ...data.params,
        ...options.query
      }
    }

    return getItems(data)
  }

  return {
    getAnalysisGroups,
    getAnalysisGroupsForFilter,
    logger
  }
}

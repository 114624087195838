import {useParseData} from '../../../utils/parse.utils';
import {useGenerateUrl} from '../../../utils/url.utils';
import {consola} from 'consola';
import {Logger} from '../../../utils/logger.utils'
import {
  AnalysisGroupTypeDto,
  AnalysisGroupTypeEntity,
  AnalysisTypeEntity,
  AnswerTypeDto,
  QuestionTypeDto
} from './analysis.type'

export const useAnalysisUtils = (settings: any) => {
  const {generateDefaultFieldDirectus, getTransItemMulti, generateImageLink, toNonAccentVietnamese} = useParseData()
  const {generateLangSwitch} = useGenerateUrl(settings)

  const runtimeConfigs = useRuntimeConfig()
  const logger = Logger(runtimeConfigs.log_level)

  const generateAnswerDto = (item: AnalysisTypeEntity, languageCode: string) => {
    try {
      let output = {
        ...generateDefaultFieldDirectus(item),
        type: item.type,
        content: getTransItemMulti(item, languageCode, 'content'),
        icon: generateImageLink({id: item.icon})
      }

      delete output['translations']
      return output
    } catch (e: any) {
      logger.error('ERR generateAnswerDto : ', e)
      return {}
    }
  }

  const generateQuestionDto = (item: AnalysisTypeEntity, languageCode: string) => {
    try {
      let output = {
        ...generateDefaultFieldDirectus(item),
        type: item.type,
        interface: item.interface,
        field_name: item.field_name,
        title_size: item.title_size,
        layout: item.layout,
        size: item.size,
        content: getTransItemMulti(item, languageCode, 'content'),
      }

      if (item.answers && Array.isArray(item.answers) && item.answers.length > 0) {
        output['answers'] = item.answers.map((ite: any) => generateAnswerDto(ite.related_analysis_id, languageCode))
      }

      delete output['translations']
      return output
    } catch (e: any) {
      logger.error('ERR generateQuestionDto : ', e)
      return {}
    }
  }

  const generateAnalysisGroupDto = (item: AnalysisGroupTypeEntity, languageCode: string): AnalysisGroupTypeDto | any => {
    try {
      let output = {
        ...generateDefaultFieldDirectus(item),
        parent: item.parent,
        children: item.children,
        name: getTransItemMulti(item, languageCode, "name"),
        title: getTransItemMulti(item, languageCode, "title"),
        slug: getTransItemMulti(item, languageCode, "slug"),
        description: getTransItemMulti(item, languageCode, "description"),
        questions_intro: getTransItemMulti(item, languageCode, "questions_intro"),
        seo_detail: getTransItemMulti(item, languageCode, "seo_detail")
      }

      if (item.questions && Array.isArray(item.questions) && item.questions.length > 0) {
        output['questions'] = item.questions.map((ite: any) => {
          let question = generateQuestionDto(ite.analysis_id, languageCode);
          // question.sort = ite.sort;
          return question;
        })
      }

      output["lang_switch"] = generateLangSwitch("analysis_slug", languageCode, item);
      delete output['translations']
      return output
    } catch (e: any) {
      logger.error('ERR generateAnalysisGroupDto : ', e);
      return {}
    }
  }

  const parseAnswers = (data: Array<AnswerTypeDto>, options: { sort: boolean }) => {

    let answers: Array<any> = [];
    data.forEach((ite: AnswerTypeDto) => {
      if (ite.status === "published") {
        const temp
          = {
          id: ite.id,
          content: ite.content,
          icon: ite.icon
        }
        answers.push(temp);
      }
    })

    if (options.sort) {
      return answers.sort(function (a, b) {
        if (toNonAccentVietnamese(a.content) < toNonAccentVietnamese(b.content)) {
          return -1;
        }
        if (toNonAccentVietnamese(a.content) > toNonAccentVietnamese(b.content)) {
          return 1;
        }
        return 0;
      });
    } else {
      return answers
    }
  }


  const parseQuestions = (data: Array<QuestionTypeDto>) => {

    let questions: any = [];
    data.forEach((ite: any) => {
      if (ite.status === "published") {
        const temp = {
          id: ite.id,
          interface: ite.interface,
          size: ite.size,
          title_size: ite.title_size,
          layout: ite.layout,
          field_name: ite.field_name,
          content: ite.content,
          answers: ite.id === 12 ? parseAnswers(ite.answers, {sort: true}) : parseAnswers(ite.answers, {sort: false}),
          sort: ite.sort
        }
        questions.push(temp)
      }
    })
    return questions;
  }

  const parseAnalysisGroup = (data: Array<AnalysisGroupTypeDto>) => {
    const analysisGroups: Array<any> = [];
    data.forEach((ite: any) => {
      if (ite.status === "published") {

        let analysisGroup: any = {
          ...ite,
        }
        if (ite.questions && ite.questions[0]) {
          analysisGroup.questions = parseQuestions(ite.questions);
        }

        analysisGroups.push(analysisGroup);
      }

    })
    return analysisGroups
  }

  return {
    generateLangSwitch,
    generateAnalysisGroupDto,
    generateAnswerDto,
    generateQuestionDto,
    parseAnalysisGroup,
    logger
  }
}

import {
  ProductBenefitTypeDTO,
  ProductBenefitTypeEntity,
  ProductCategoryTypeDTO,
  ProductCategoryTypeEntity,
  ProductPlanTypeDTO,
  ProductPlanTypeEntity,
  ProductSearchTermTypeDto,
  ProductSearchTermTypeEntity,
  ProductTypeDTO,
  ProductTypeEntity,
  ProductUsageCaseTypeEntity
} from './useProductType'
import arrayToTree from 'array-to-tree'
import {Logger} from '../../../utils/logger.utils'
import {useParseData} from '../../../utils/parse.utils'
import {useGenerateUrl} from '../../../utils/url.utils'
import {useCarrierUtils} from '../carrier/carrier.utils'
import {useFeedbackUtils} from './../products_feedback/useFeedbackUtils'
import {consola} from "consola";

export const useProductUtils = (settings: any) => {
  const {generateDefaultFieldDirectus, getTransItemMulti, getImageField, toNonAccentVietnamese} = useParseData()
  const {generateLangSwitch, generateUrlByNameRoute} = useGenerateUrl(settings);
  const {generateCarrierDto} = useCarrierUtils(settings);
  const {generateFeedbackDTO} = useFeedbackUtils(settings);
  const {generateFaqDto} = useFaqUtils(inject('globalSettings'));
  const runtimeConfigs = useRuntimeConfig();
  const logger = Logger(runtimeConfigs.log_level);

  const generateProductDTO = (item: ProductTypeEntity, languageCode: string): ProductTypeDTO | any => {
    const locale_code = languageCode === 'vi-VI' ? 'vi' : languageCode
    try {
      let output = {
        ...generateDefaultFieldDirectus(item),
        carrierProductId: item.carrierProductId ?? null,
        thumbnail: item.thumbnail ? getImageField(item, 'thumbnail') : null,
        banner_desktop: item.banner_desktop ? getImageField(item, 'banner_desktop') : null,
        banner_mobile: item.banner_mobile ? getImageField(item, 'banner_mobile') : null,
        title: getTransItemMulti(item, languageCode, 'title'),
        slug: getTransItemMulti(item, languageCode, 'slug'),
        intro: getTransItemMulti(item, languageCode, 'intro'),
        data_raw: getTransItemMulti(item, languageCode, 'data_raw'),
        content: getTransItemMulti(item, languageCode, 'content'),
        seo_detail: getTransItemMulti(item, languageCode, 'seo_detail'),
        onelink_url: item.onelink_url,
        show_documents: item.show_documents,
        brochure_download: getTransItemMulti(item, languageCode, 'brochure_download'),
        term_download: getTransItemMulti(item, languageCode, 'term_download'),
        compensation_guide: getTransItemMulti(item, languageCode, 'compensation_guide'),
        url: generateUrlByNameRoute('product_detail', languageCode, {
          id: item.id,
          slug: getTransItemMulti(item, languageCode, 'slug')
        }),
        translations: {
          title: getTransItemMulti(item, languageCode, 'title'),
          slug: getTransItemMulti(item, languageCode, 'slug'),
          intro: getTransItemMulti(item, languageCode, 'intro'),
        }
      }

      if (output.hasOwnProperty('data_raw')) {
        delete output.data_raw
      }

      if (item.category && typeof item.category === 'object') {
        output['category'] = generateProductCategoryDTO(item.category, languageCode)
      }

      if (item.carrier && typeof item.carrier === 'object') {
        output['carrier'] = generateCarrierDto(item.carrier, languageCode)
      }

      if (item.plans && Array.isArray(item.plans)) {
        output['plans'] = item.plans.reduce((previousValue: any, currentValue: any) => {
          if (currentValue && typeof currentValue === 'object') {
            previousValue.push(generateProductPlanDTO(currentValue, languageCode))
          }
          return previousValue
        }, [])

        output['benefits'] = output.plans.reduce((previousValue: any, currentValue: any) => {
          for (let benefit of currentValue.benefits) {
            let index = previousValue.find((item: any) => item.code === benefit.code)
            if (!index) {
              previousValue.push(benefit)
            }
          }
          return previousValue
        }, [])

        if (Array.isArray(output['plans']) && output['plans'].length > 0) {
          let maxPrem = output['plans'].reduce((previousValue: any, currentValue: any) => {
            if (currentValue.premium > previousValue) {
              return currentValue.premium
            }
            return previousValue
          }, -Infinity)

          let minPrem = output['plans'].reduce((previousValue: any, currentValue: any) => {
            if (currentValue.premium < previousValue) {
              return currentValue.premium
            }
            return previousValue
          }, Infinity)

          if (minPrem && maxPrem && minPrem != 0 && maxPrem != 0) {
            output['premium'] =
              minPrem == maxPrem
                ? parseFloat(minPrem.toString()).toLocaleString(locale_code) + ' đ'
                : parseFloat(minPrem.toString()).toLocaleString(locale_code) +
                ' đ - ' +
                parseFloat(maxPrem.toString()).toLocaleString(locale_code) +
                ' đ'
          } else {
            output['premium'] = output['plans'][0]?.premium_description || null
            output['type_insured_period']
          }

          let maxSum = output['plans']
            .reduce((previousValue: any, currentValue: any) => {
              let items = previousValue.filter((item: any) => currentValue.premium > item.premium)

              if (previousValue.length === 0) {
                previousValue.push(currentValue)
              }

              if (items.length > 0) {
                previousValue = []
                previousValue.push(currentValue)
              }

              let items1 = previousValue.filter((item: any) => currentValue.premium == item.premium)
              if (items1.length > 0) {
                previousValue.push(currentValue)
              }
              return previousValue
            }, [])
            .reduce((previousValue: any, currentValue: any) => {
              if (currentValue.sum_insured > previousValue) {
                return currentValue.sum_insured
              }
              return previousValue
            }, -Infinity)

          let minSum = output['plans']
            .reduce((previousValue: any, currentValue: any) => {
              let items = previousValue.filter((item: any) => currentValue.premium < item.premium)

              if (previousValue.length === 0) {
                previousValue.push(currentValue)
              }

              if (items.length > 0) {
                previousValue = []
                previousValue.push(currentValue)
              }

              let items1 = previousValue.filter((item: any) => currentValue.premium == item.premium)
              if (items1.length > 0) {
                previousValue.push(currentValue)
              }
              return previousValue
            }, [])
            .reduce((previousValue: any, currentValue: any) => {
              if (currentValue.sum_insured < previousValue) {
                return currentValue.sum_insured
              }
              return previousValue
            }, Infinity)

          if (minSum && maxSum && minSum != 0 && maxSum != 0) {
            output['sum_insured'] =
              minSum == maxSum
                ? parseFloat(minSum.toString()).toLocaleString(locale_code) + ' đ '
                : parseFloat(minSum.toString()).toLocaleString(locale_code) +
                ' đ - ' +
                parseFloat(maxSum.toString()).toLocaleString(locale_code) +
                ' đ'
          } else {
            output['sum_insured'] = output['plans'][0]?.sum_insured_description || null
          }

          let max_insured_period = output['plans']
            .reduce((previousValue: any, currentValue: any) => {
              let items = previousValue.filter((item: any) => currentValue.premium > item.premium)

              if (previousValue.length === 0) {
                previousValue.push(currentValue)
              }

              if (items.length > 0) {
                previousValue = []
                previousValue.push(currentValue)
              }

              let items1 = previousValue.filter((item: any) => currentValue.premium == item.premium)
              if (items1.length > 0) {
                previousValue.push(currentValue)
              }
              return previousValue
            }, [])
            .reduce((previousValue: any, currentValue: any) => {
              let value = 0

              switch (currentValue.type_insured_period) {
                case 'months':
                  value = parseInt(currentValue.insured_period)
                  break
                case 'years':
                  value = parseInt(currentValue.insured_period) * 12
                  break
              }

              if (Object.keys(previousValue).length === 0 || value >= previousValue.value) {
                previousValue = {
                  insured_period: currentValue.insured_period,
                  type_insured_period: currentValue.type_insured_period,
                  value
                }
              }
              return previousValue
            }, {})

          output['max_insured_period'] = max_insured_period

          let min_insured_period = output['plans']
            .reduce((previousValue: any, currentValue: any) => {
              let items = previousValue.filter((item: any) => currentValue.premium < item.premium)

              if (previousValue.length === 0) {
                previousValue.push(currentValue)
              }

              if (items.length > 0) {
                previousValue = []
                previousValue.push(currentValue)
              }

              let items1 = previousValue.filter((item: any) => currentValue.premium == item.premium)
              if (items1.length > 0) {
                previousValue.push(currentValue)
              }
              return previousValue
            }, [])
            .reduce((previousValue: any, currentValue: any) => {
              let value = 0

              switch (currentValue.type_insured_period) {
                case 'months':
                  value = parseInt(currentValue.insured_period)
                  break
                case 'years':
                  value = parseInt(currentValue.insured_period) * 12
                  break
              }

              if (Object.keys(previousValue).length === 0 || value <= previousValue.value) {
                previousValue = {
                  insured_period: currentValue.insured_period,
                  type_insured_period: currentValue.type_insured_period,
                  value
                }
              }
              return previousValue
            }, {})
          output['min_insured_period'] = min_insured_period

          // output['premium'] = {
          //     max: item.max_prem ?? output['plans'].reduce((previousValue: any, currentValue: any) => {
          //         if (currentValue.premium > previousValue) {
          //             return currentValue.premium
          //         }
          //         return previousValue
          //     }, -Infinity),
          //     min: item.min_prem ?? output['plans'].reduce((previousValue: any, currentValue: any) => {
          //         if (currentValue.premium < previousValue) {
          //             return currentValue.premium
          //         }
          //         return previousValue
          //     }, Infinity)
          // }

          // output['sum_insured'] = {
          //     max: output['plans'].reduce((previousValue: any, currentValue: any) => {
          //         if (currentValue.sum_insured > previousValue) {
          //             return currentValue.sum_insured
          //         }
          //         return previousValue
          //     }, -Infinity),
          //     min: output['plans'].reduce((previousValue: any, currentValue: any) => {
          //         if (currentValue.sum_insured < previousValue) {
          //             return currentValue.sum_insured
          //         }
          //         return previousValue
          //     }, Infinity)
          // }

          output['documents'] = output['plans'].reduce((previousValue: any, currentValue: any) => {
            if (
              currentValue.documents &&
              Array.isArray(currentValue.documents) &&
              currentValue.documents.length > 0
            ) {
              previousValue.push(...currentValue.documents)
            }
            return previousValue
          }, [])

          output['policies'] = output['plans']
            .reduce((previousValue: any, currentValue: any) => {
              if (
                currentValue.policies &&
                Array.isArray(currentValue.policies) &&
                currentValue.policies.length > 0
              ) {
                previousValue.push(...currentValue.policies)
              }
              return previousValue
            }, [])
            .reduce((previousValue: any, currentValue: any) => {
              if (!previousValue[currentValue.type]) {
                previousValue[currentValue.type] = []
              }
              previousValue[currentValue.type].push(currentValue.content)

              return previousValue
            }, {})

          output['benefits_parse'] = parseBenefitsToArray2D(output['plans'], languageCode)
        }
      }

      if (item.feedbacks) {
        output['feedbacks'] = item.feedbacks.reduce((previousValue: any, currentValue: any) => {
          if (currentValue && typeof currentValue === 'object') {
            previousValue.push(generateFeedbackDTO(currentValue, languageCode))
          }
          return previousValue
        }, [])
      }
      if (item.plans && Array.isArray(item.plans)) {
        output['plans'] = item.plans.reduce((previousValue: any, currentValue: any) => {
          if (currentValue && typeof currentValue === 'object') {
            previousValue.push(generateProductPlanDTO(currentValue, languageCode))
          }
          return previousValue
        }, [])

        output['benefits'] = output.plans.reduce((previousValue: any, currentValue: any) => {
          for (let benefit of currentValue.benefits) {
            let index = previousValue.find((item: any) => item.code === benefit.code)
            if (!index) {
              previousValue.push(benefit)
            }
          }

          return previousValue
        }, [])
      }

      if (Array.isArray(item.faqs) && item.faqs.length > 0 && typeof item.faqs[0] === 'object') {
        output['faqs'] = output['faqs'].map((ite: any) => generateFaqDto(ite, languageCode))
      }

      if (
        Array.isArray(item.product_faqs) &&
        item.product_faqs.length > 0 &&
        typeof item.product_faqs[0]?.faqs_id === 'object'
      ) {
        output['product_faqs'] = output['product_faqs'].map((ite: any) =>
          generateFaqDto(ite.faqs_id, languageCode)
        )
      }

      if (
        Array.isArray(item.product_usage_cases) &&
        item.product_usage_cases.length > 0 &&
        typeof item.product_usage_cases[0] === 'object'
      ) {
        output['product_usage_cases'] = output['product_usage_cases'].map((ite: any) =>
          generateProductUsageCaseDto(ite, languageCode)
        )
      }

      output['lang_switch'] = generateLangSwitch('product_detail', languageCode, item)
      // delete output['translations']
      return output
    } catch (e: any) {
      logger.error('ERR generateProductDto : ', e)
      return {}
    }
  }

  const parseBenefitsToArray2D = (plans: any, languageCode: string) => {
    const locale_code = languageCode === 'vi-VI' ? 'vi' : languageCode

    const initArray2D = () => {
      let array2D: Array<Array<any>> = [['label_benefit']]
      plans = plans.sort((a: any, b: any) => a.sort - b.sort);
      for (let plan of plans) {
        array2D[0].push({
          id: plan.id,
          is_active: plan.is_active,
          title: plan.title,
          benefits: plan.benefits,
          premium: plan.premium,
          premium_description: plan.premium_description,
          sum_insured: plan.sum_insured
        })

        let benefits = plan.benefits.sort((a: any, b: any) => a.sort - b.sort).map((ite: any) => ({
            id: ite.id,
            sort: ite.sort,
            code: ite.code,
            title: removeTags(ite.title),
            type: ite.type,
            icon: ite.icon,
            benefitType: ite.benefitType,
            notes: ite.data?.filter((ie: any) => (ie.value && ie.value > 0 && ie.value !== 'NA') || ie.description),
            parentBenefit: ite.parentBenefit
          }
        ))

        let parentBenefits = benefits
          .filter((item: any) => item.parentBenefit === 'NA');
        benefits = arrayToTree(
          benefits,
          {
            parentProperty: 'parentBenefit',
            customID: 'code'
          }
        );

        for (let benefit of benefits) {

          let index = array2D.some((item: any) => {
            return item.some((ite: any) => {
              return (
                typeof ite === 'object' &&
                ite.id &&
                ite.code === benefit.code &&
                ite.code === benefit.code
              )
            })
          })
          // console.log({ index, benefit })

          if (!index) {
            array2D = [
              ...array2D,
              [
                {
                  ...benefit
                },
                ...plans.map((planItem: any) => {
                    let tempBenefits = planItem.benefits.filter((ite: any) => (
                      !parentBenefits.some((ie: any) => ie.code === ite.code)
                    ))

                    let planItemBenefits1 = arrayToTree(
                      (
                        tempBenefits.map((ite: any) => ({
                          id: ite.id,
                          title: ite.sum_insured > 0 ? ite.sum_insured.toLocaleString(locale_code) + " đ" : (ite.sum_insured_description || null),
                          description: ite.description,
                          code: ite.code,
                          parentBenefit: ite.parentBenefit,
                          benefitType: ite.benefitType,
                          notes: ite.data?.filter((ie: any) => (ie.value && ie.value > 0 && ie.value !== 'NA') || ie.description).map((ie: any) => {
                            return {
                              title: (ie.value > 0 && ie.value !== 'NA') ? ie.value.toLocaleString(locale_code) + " đ" : null,
                              description: ie.description || null
                            }
                          }) || []
                        }))
                      )
                      , {
                        parentProperty: 'parentBenefit',
                        customID: 'code'
                      })

                    return ({
                      data: {},
                      children: planItemBenefits1.filter((ite: any) => ite.parentBenefit === benefit.code)
                    })
                  }
                )
              ]
            ]
          }
        }
      }
      return array2D
    }

    const pushDataArray2D = (array2D: any) => {
      let plans = array2D[0]
      // consola.info(JSON.stringify(array2D, null, 2))

      for (let i = 1; i < plans.length; i++) {
        let column = i

        for (let j = 1; j < array2D.length; j++) {
          let row = j

          // consola.info({ benefits: plans[i].benefits })

          let index = plans[i].benefits.findIndex(
            (ite: any) =>
              typeof ite === 'object' &&
              ite.code === array2D[j][0].code
          )
          // console.log({ row, column, index })
          if (index >= 0) {
            let sum_insured = {
              id: plans[i].benefits[index].id,
              benefitType: plans[i].benefits[index].benefitType,
              title: plans[i].benefits[index].sum_insured > 0 ? parseFloat(plans[i].benefits[index].sum_insured).toLocaleString(locale_code) + " đ" : (plans[i].benefits[index].sum_insured_description || null),
              notes: plans[i].benefits[index]?.data?.filter((ie: any) => (ie.value > 0 && ie.value !== 'NA') || ie.description).map((ite: any) => {
                return {
                  title: (ite.value && ite.value != 0) ? parseFloat(ite.value).toLocaleString(locale_code) + " đ" : null,
                  description: ite.description || null
                  //sub_title: ite.title || null,
                }
              }) || []
            }
            // console.log({
            //     sum_insured: plans[i].benefits[index].sum_insured,
            //     sum_insured_description: plans[i].benefits[index].sum_insured_description,
            //     data: JSON.stringify(plans[i].benefits[index].data)
            // });

            // console.log(plans[i].benefits[index]);

            array2D[row][column].data = sum_insured
          }
        }
      }

      let plansUpdate = [...plans]
      plansUpdate.shift()

      array2D.push([
        'label_premium',
        ...plansUpdate.map((item: any) => ({
          data: {
            title: item.premium && item.premium != 0
              ? parseFloat(item.premium).toLocaleString(locale_code) + ' đ'
              : item.premium_description
          }
        }))
      ])

      return array2D
    }

    let arrayInit = initArray2D()

    // logger.warn(JSON.stringify(arrayInit, null, 2))

    let result = pushDataArray2D(arrayInit)

    // logger.warn(JSON.stringify({ result }, null, 2))

    const transpose = (matrix: Array<any>) => {
      return Object.keys(matrix[0]).map((colNumber) => matrix.map((rowNumber) => rowNumber[colNumber]))
    }

    let index_first = result.shift()
    let index_last = result.pop()

    result = [index_first, ...transpose(result), index_last]
    result = updateBenefitParent(result)

    // console.log('🚀 ~ file: useProductUtils.ts:551 ~ parseBenefitsToArray2D ~ result:', result)
    return result
  }


  const updateBenefitParent = (input: Array<any>) => {
    let output = [...input];
    for (let i = 3; i < input.length - 1; i++) {
      output[i] = output[i].map((element: any, index: any) => {
        let parent = input[2][index];
        if (element.children && element.children[0]) {
          element = {
            ...recursiveUpdate(element, parent)
          };
        }
        return element
      });
    }
    return output
  }

  const recursiveUpdate = (element: any, parent: any) => {
    try {
      let output = {...element};

      if (output.children && output.children[0]) {
        if (output.data) {

          output.data = {
            ...output.data,
            id: parent.data.id
          }
        }
        output.children = output.children.map((ite: any, index: any) => {
          return recursiveUpdate(ite, parent.children[index])
        })

        if (parent.id) {
          output.id = parent.id
        }
        return {
          ...output,
          // id: parent.id
        }
      } else {

        if (parent.id) {
          output.id = parent.id
        }

        return {
          ...output,
          children: [],
          // id: parent.id
        }
      }

    } catch (e) {
      logger.error('ERR recursiveUpdate: ', e)
      logger.error('element : ', element)
      logger.error('parent: ', parent)
    }
  }

  const generateProductCategoryDTO = (
    item: ProductCategoryTypeEntity,
    languageCode: string
  ): ProductCategoryTypeDTO | any => {
    try {
      let output = {
        ...generateDefaultFieldDirectus(item),
        title: getTransItemMulti(item, languageCode, 'title'),
        slug: getTransItemMulti(item, languageCode, 'slug'),
        description: getTransItemMulti(item, languageCode, 'description')
        // url: generateUrlSearchFilter('products_search', languageCode, {
        //     category: item.id
        // })
        //url_test: generateLangSwitch('products', languageCode)
      }

      if (output.hasOwnProperty('data_raw')) {
        delete output.data_raw
      }

      if (item.products && Array.isArray(item.products)) {
        output['products'] = item.products.reduce((previousValue: any, currentValue: any) => {
          if (currentValue && typeof currentValue === 'object') {
            previousValue.push(generateProductDTO(currentValue, languageCode))
          }
          return previousValue
        }, [])
      }

      //output["lang_switch"] = generateLangSwitch("products_category", languageCode,item );
      delete output['translations']
      return output
    } catch (e: any) {
      logger.error('ERR generateProductCategoryDTO : ', e)
      return {}
    }
  }

  const generateProductPlanDTO = (item: ProductPlanTypeEntity, languageCode: string): ProductPlanTypeDTO | any => {
    try {
      let output = {
        ...generateDefaultFieldDirectus(item),
        carrierPlanId: item.carrierPlanId ?? null,
        is_active: item.is_active ?? false,
        premium: item.premium ?? null,
        sum_insured: item.sum_insured ?? null,
        type_insured_period: item.type_insured_period ?? null,
        insured_period: item.insured_period ?? null,
        title: getTransItemMulti(item, languageCode, 'title'),
        documents: getTransItemMulti(item, languageCode, 'documents'),
        policies: getTransItemMulti(item, languageCode, 'policies'),
        premium_description: getTransItemMulti(item, languageCode, 'premium_description'),
        sum_insured_description: getTransItemMulti(item, languageCode, 'sum_insured_description')
      }
      if (output.hasOwnProperty('data_raw')) {
        delete output.data_raw
      }
      if (item.carrier && typeof item.carrier === 'object') {
        output['carrier'] = generateCarrierDto(item.carrier, languageCode)
      }

      if (item.product && typeof item.product === 'object') {
        output['product'] = generateProductDTO(item.product, languageCode)
      }

      if (item.benefits && Array.isArray(item.benefits) && item.benefits.length > 0) {
        output['benefits'] = item.benefits.map((ite: any) => {
          return {
            sum_insured: ite.sum_insured ?? 0,
            data: getTransItemMulti(ite, languageCode, 'data'),
            sum_insured_description: getTransItemMulti(ite, languageCode, 'sum_insured_description'),

            //data: ite.data,
            ...generateProductPlanBenefitDTO(ite.benefit_id, languageCode)
          }
        })
      }

      //output['lang_switch'] = generateLangSwitch('products_plan_detail', languageCode, item)
      delete output['translations']
      return output
    } catch (e: any) {
      logger.error('ERR generateProductPlansDTO : ', e)
      return {}
    }
  }

  const generateProductPlanBenefitDTO = (
    item: ProductBenefitTypeEntity,
    languageCode: string
  ): ProductBenefitTypeDTO | any => {
    try {
      if (item) {
        let output = {
          ...generateDefaultFieldDirectus(item),
          code: item.code ?? null,
          type: item.type ?? null,
          icon: item.icon ? getImageField(item, 'icon') : null,
          title: getTransItemMulti(item, languageCode, 'title'),
          sub_title: removeTags(getTransItemMulti(item, languageCode, 'sub_title')),
          intro: removeTags(getTransItemMulti(item, languageCode, 'intro')),
          short_name: removeTags(getTransItemMulti(item, languageCode, 'short_name')),
          description: removeTags(getTransItemMulti(item, languageCode, 'description'))
        }
        if (output.hasOwnProperty('data_raw')) {
          delete output.data_raw
        }
        // output['lang_switch'] = generateLangSwitch('products_benefit_detail', languageCode, item)
        delete output['translations']
        return output
      }
    } catch (e: any) {
      logger.error('ERR generateProductPlanBenefitDTO : ', e)
      return {}
    }
  }

  const removeTags = (str: string) => {
    try {
      if (!str || str === '') return null
      else str = str.toString()
      let result = str.replace(/(<([^>]+)>)/gi, '')
      return result.replaceAll(';', '; ')
    } catch (e) {
      logger.error('ERR removeTags: ', e)
      return null
    }
  }

  const generateProductSearchTerm = (item: ProductSearchTermTypeEntity, languageCode: string) => {
    try {
      let output = {
        ...generateDefaultFieldDirectus(item),
        type: item.type ?? null,
        gte: item.gte ?? null,
        lte: item.lte ?? null,
        title: getTransItemMulti(item, languageCode, 'title')
      }
      delete output['translations']
      return output
    } catch (e: any) {
      logger.error('ERR generateProductSeasrchTerm : ', e)
      return {}
    }
  }

  const generateProductUsageCaseDto = (item: ProductUsageCaseTypeEntity, languageCode: string) => {
    try {
      let output = {
        ...generateDefaultFieldDirectus(item),
        image: item.image,
        content: getTransItemMulti(item, languageCode, 'content')
      }

      delete output['translations']
      return output
    } catch (e: any) {
      logger.error('ERR generateProductUsageCaseDto: ', e)
      return {}
    }
  }

  const groupProductsSearchTerm = (data: Array<ProductSearchTermTypeDto>) => {
    try {
      let terms = data.map((ite: any) => {
        return ite.type
      })
      terms = [...new Set(terms)]

      return terms.reduce(function (output, currentValue) {
        let items = data.filter((ite: any) => ite.type === currentValue)
        if (items[0] && items[0].lte) {
          output[currentValue] = items.sort(function (a, b) {
            if (a.gte && b.gte) {
              if (a.gte < b.gte) {
                return -1
              }
              if (a.gte > b.gte) {
                return 1
              }
            }
            return 0
          })
        } else {
          output[currentValue] = items.sort(function (a, b) {
            if (toNonAccentVietnamese(a.title) < toNonAccentVietnamese(b.title)) {
              return -1
            }
            if (toNonAccentVietnamese(a.title) > toNonAccentVietnamese(b.title)) {
              return 1
            }
            return 0
          })
        }
        return output
      }, {})
    } catch (e) {
      logger.error('ERR groupProductsSearchTerm: ', e);
    }
  }

  return {
    generateLangSwitch,
    generateProductDTO,
    generateProductCategoryDTO,
    generateProductPlanDTO,
    generateProductPlanBenefitDTO,
    generateCarrierDto,
    generateProductSearchTerm,
    groupProductsSearchTerm,
    logger
  }
}

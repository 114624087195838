import {Query} from '@directus/types'
import {consola} from 'consola'
import {useProductUtils} from './useProductUtils'
import {
  ProductCategoryTypeEntity,
  ProductTypeDTO,
  ProductTypeEntity
} from './useProductType'
import arrayToTree from 'array-to-tree'
import {TypeParamsCheckExistActivePolicies, TypeParamsGetPolicies} from '../../../server/types/verify.type'
import {useAnalysis} from '../analysis/useAnalysis'

export const useProducts = (settingsData?: any) => {
  const {getItems, getItemById} = useDirectusItems();
  const {toNonAccentVietnamese} = useParseData();
  const settings: any = settingsData || inject('globalSettings')
  const {
    generateProductDTO,
    generateCarrierDto,
    generateProductCategoryDTO,
    generateLangSwitch,
    generateProductPlanBenefitDTO,
    generateProductSearchTerm,
    groupProductsSearchTerm,

  } = useProductUtils(settings)

  const {getAnalysisGroupsForFilter} = useAnalysis();

  const getProductsIndex = async (languageCode: string, page: number = 1) => {
    return searchProducts(
      {lang: languageCode},
      {
        limit: 9,
        page
      }
    )
      .then((result: any) => {
        let {data} = result
        return {
          model: data.value.products,
          filter_count: data.value.filter_count || 0,
          lang_switch: generateLangSwitch('products', languageCode)
        }
      })
      .catch((e) => {
        consola.error('ERR getProductsIndex : ', e.message)
        consola.warn(e)
        return {
          model: [],
          filter_count: 0,
          lang_switch: generateLangSwitch('products', languageCode)
        }
      })
  }

  // const getProductById = (id: string | number, languageCode: string) => {
  //     return useAsyncData(() =>
  //         getProductsEntity({
  //             id,
  //             query: {
  //                 fields: [
  //                     'id', 'status', 'sort', 'show_home', 'mega',
  //                     'carrierProductId', 'code', 'max_prem', 'min_prem',
  //                     'type_insured_period', 'insured_period',
  //                     'onelink_url', 'show_documents',
  //                     'thumbnail.id',
  //                     'thumbnail.title',
  //                     'thumbnail.description',
  //                     'banner_desktop.id',
  //                     'banner_desktop.title',
  //                     'banner_desktop.description',
  //                     'banner_mobile.id',
  //                     'banner_mobile.title',
  //                     'banner_mobile.description',
  //                     'translations.*',
  //                     'translations.seo_details.*',
  //                     'category.id',
  //                     'category.status',
  //                     'category.translations.id',
  //                     'category.translations.languages_code',
  //                     'category.translations.title',
  //                     'carrier.id',
  //                     'carrier.status',
  //                     'carrier.sort',
  //                     'carrier.url',
  //                     'carrier.show',
  //                     'carrier.thumbnail.id',
  //                     'carrier.thumbnail.title',
  //                     'carrier.thumbnail.description',
  //                     'carrier.translations.id',
  //                     'carrier.translations.languages_code',
  //                     'carrier.translations.title',
  //                     'plans.id',
  //                     'plans.status',
  //                     'plans.sort',
  //                     'plans.is_active',
  //                     'plans.carrier',
  //                     'plans.premium',
  //                     'plans.product',
  //                     'plans.sum_insured',
  //                     'plans.carrierPlanId',
  //                     'plans.type_insured_period',
  //                     'plans.insured_period',
  //                     'plans.translations.*',
  //                     'plans.benefits.*',
  //                     'plans.benefits.translations.*',
  //                     'plans.benefits.benefit_id.*',
  //                     'plans.benefits.benefit_id.translations.*',
  //                     'plans.benefits.benefit_id.icon.id',
  //                     'plans.benefits.benefit_id.icon.title',
  //                     'plans.benefits.benefit_id.icon.description',
  //                     'product_faqs.*',
  //                     'product_faqs.faqs_id.*',
  //                     'product_faqs.faqs_id.translations.*',
  //                     "product_usage_cases.*",
  //                     "product_usage_cases.image.id",
  //                     "product_usage_cases.image.title",
  //                     "product_usage_cases.image.description",
  //                     "product_usage_cases.translations.*"
  //                 ],
  //                 filter: {
  //                     _and: [
  //                         {
  //                             status: {
  //                                 _eq: 'published'
  //                             }
  //                         },
  //                         {
  //                             category: {
  //                                 status: {
  //                                     _eq: 'published'
  //                                 }
  //                             }
  //                         },
  //                         {
  //                             plans: {
  //                                 status: {
  //                                     _eq: 'published'
  //                                 }
  //                             }
  //                         },
  //                         {
  //                             carrier: {
  //                                 status: {
  //                                     _eq: 'published'
  //                                 }
  //                             }
  //                         }
  //                     ]
  //                 }
  //             }
  //         })
  //             .then((product) => {
  //                 return {
  //                     model:
  //                         Object.keys(product).length > 0
  //                             ? generateProductDTO(product as ProductTypeEntity, languageCode)
  //                             : {},
  //                     lang_switch:
  //                         Object.keys(product).length > 0
  //                             ? generateProductDTO(product as ProductTypeEntity, languageCode)['lang_switch']
  //                             : []
  //                 }
  //             })
  //             .catch((e) => {
  //                 consola.error('ERR getProductById : ', e.message)
  //                 consola.debug(e)
  //             })
  //     )
  // }

  const getProductBySlug = async (slug: string, languageCode: string, seo_fields: string[] = []) => {
    return getProductsEntity({
      query: {
        fields: [
          'id', 'status',
          'show_home', 'mega',
          'carrierProductId', 'code',
          'max_prem', 'min_prem',
          'type_insured_period', 'insured_period',
          'onelink_url', 'show_documents',
          'thumbnail.id',
          'thumbnail.title',
          'thumbnail.description',
          'banner_desktop.id',
          'banner_desktop.title',
          'banner_desktop.description',
          'banner_mobile.id',
          'banner_mobile.title',
          'banner_mobile.description',
          'translations.languages_code',
          'translations.title',
          'translations.content',
          'translations.intro',
          'translations.slug',
          'translations.brochure_download',
          'translations.term_download',
          'translations.compensation_guide',
          'translations.seo_detail.*',
          'category.id',
          'category.status',
          'category.translations.id',
          'category.translations.languages_code',
          'category.translations.title',
          'carrier.id',
          'carrier.status',
          'carrier.url',
          'carrier.show',
          'carrier.thumbnail.id',
          'carrier.thumbnail.title',
          'carrier.thumbnail.description',
          'carrier.translations.id',
          'carrier.translations.languages_code',
          'carrier.translations.title',
          'plans.id',
          'plans.status',
          'plans.sort',
          'plans.is_active',
          'plans.carrier',
          'plans.premium',
          'plans.product',
          'plans.sum_insured',
          'plans.carrierPlanId',
          'plans.type_insured_period',
          'plans.insured_period',
          'plans.translations.*',
          'plans.benefits.*',
          'plans.benefits.translations.*',
          'plans.benefits.benefit_id.*',
          'plans.benefits.benefit_id.translations.*',
          'plans.benefits.benefit_id.icon.id',
          'plans.benefits.benefit_id.icon.title',
          'plans.benefits.benefit_id.icon.description',
          'product_usage_cases.*',
          'product_usage_cases.image.*',
          'product_usage_cases.translations.*',
          ...seo_fields
        ],
        filter: {
          translations: {
            slug: {
              _eq: slug
            }
          }
        }
      }
    })
      .then((product) => {
        return {
          model:
            Object.keys(product).length > 0
              ? generateProductDTO(product[0] as ProductTypeEntity, languageCode)
              : {},
          lang_switch:
            Object.keys(product).length > 0
              ? generateProductDTO(product[0] as ProductTypeEntity, languageCode)['lang_switch']
              : []
        }
      })
      .catch((e) => {
        consola.error('ERR getProductBySlug : ', e.message)
        consola.debug(e)
      })
  }

  const getProductsByCategory = async (nid: string, category: string, languageCode: string) => {
    return getProductsEntity({
      query: {
        fields: [
          'id',
          'status',
          'sort',
          'carrierProductId',
          'category',
          'thumbnail.id',
          'thumbnail.title',
          'thumbnail.description',
          'banner_desktop.id',
          'banner_desktop.title',
          'banner_desktop.description',
          'banner_mobile.id',
          'banner_mobile.title',
          'banner_mobile.description',
          'translations.languages_code',
          'translations.title',
          'translations.content',
          'translations.intro',
          'translations.slug',
          'carrier.thumbnail.id',
          'carrier.url',
          'carrier.thumbnail.title',
          'carrier.description',
          // "product_usage_cases.id",
          // "product_usage_cases.status",
          // "product_usage_cases.image.id",
          // "product_usage_cases.image.title",
          // "product_usage_cases.image.description",
          // "product_usage_cases.translations.*"
        ],
        filter: {
          _and: [
            {
              status: {
                _eq: 'published'
              }
            },
            {
              category: {
                _eq: category
              }
            },
            {
              id: {
                _neq: nid
              }
            }
          ]
        },
        sort: ['-sort', '-date_created'],
        limit: 6,
        page: 1
      }
    })
      .then((products) => {
        if (products && Array.isArray(products)) {
          return products.map((item: any) => generateProductDTO(item, languageCode))
        } else return []
      })
      .catch((error) => {
        consola.error('Error: getProductsWithCategory')
        consola.debug(error)
        return []
      })
  }

  const getProductsCategory = async (languageCode: string) => {
    return getProductsCategoryEntity({
      query: {
        limit: -1,
        fields: ['*', 'translations.languages_code', 'translations.title'],
        filter: {
          status: {
            _eq: 'published'
          }
        }
      }
    })
      .then((categories) => {
        if (!categories || !Array.isArray(categories)) throw new Error('Error: getProductsCategory')

        return categories.map((item: any) => generateProductCategoryDTO(item, languageCode))
      })
      .catch((error) => {
        console.log("Error getProductsCategory: ", error)
        return []
      })
  }

  const searchProducts = async (
    options: {
      text?: string
      carrier?: Array<string | number>
      category?: Array<string | number>
      benefit?: Array<string | number>
      price_range?: Array<any>
      from?: string | number
      to?: string | number
      object?: Array<string | number>
      utilities?: Array<string | number>
      lang?: string
    },
    query?: Query | any
  ): Promise<Array<ProductTypeDTO> | any> => {

    let _and: Array<any> = [
      {
        status: {
          _eq: 'published'
        }
      },
      {
        carrier: {
          status: {
            _eq: 'published'
          }
        }
      },
      {
        category: {
          status: {
            _eq: 'published'
          }
        }
      }
    ]

    if (options.text) {
      _and.push({
        _and: [
          {
            translations: {
              languages_code: {
                _in: options.lang || 'vi-VI'
              }
            }
          },
          {
            _or: [
              {
                translations: {
                  title: {
                    _icontains: options.text.toString()
                  }
                }
              },
              // {
              //     translations: {
              //         intro: {
              //             _icontains: options.text.toString()
              //         }
              //     }
              // }
            ]
          }
        ]
      })
    }

    if (options.category && options.category[0]) {
      _and.push({
        _or: [
          {
            category: {
              id: {
                _in: options.category
              }
            }
          },
          {
            sub_categories: {
              products_category_id: {

                _in: options.category

              }
            }
          }
        ]
      })
    }

    if (options.carrier && options.carrier[0]) {
      _and.push({
        carrier: {
          id: {
            _in: options.carrier
          }
        }
      })
    }


    if (options.object && options.object[0] && (options.utilities && !options.utilities[0])) {
      _and.push({
        search_terms: {
          search_term_id: {
            _in: options.object
          }
        }
      })
    }

    if (options.utilities && options.utilities[0] && (options.object && !options.object[0])) {
      _and.push({
        search_terms: {
          search_term_id: {
            _in: options.utilities
          }
        }
      })
    }


    if (options.object && options.utilities) {
      if (options.object[0] && options.utilities[0]) {
        _and.push({
          _or: [
            {
              search_terms: {
                search_term_id: {
                  _in: options.object
                }
              }
            },
            {
              search_terms: {
                search_term_id: {
                  _in: options.utilities
                }
              }
            }
          ]
        })
      }
    }

    if (options.price_range && options.price_range[0]) {
      let _or: any = [];
      options.price_range.forEach((ite: any) => {
        let and: any = [];
        if (ite.gte) {
          and.push(
            {
              plans: {
                premium: {
                  _gte: ite.gte
                }
              }
            },
          )
        }

        if (ite.lte) {
          and.push(
            {
              plans: {
                premium: {
                  _lte: ite.lte
                }
              }
            }
          )
        }
        _or.push({
          _and: and
        })
      })
      _and.push({
        _or: _or
      })
    }

    if (options.benefit && options.benefit[0]) {
      let _orBenefits = options.benefit.map(ite => (
        {
          plans: {
            benefits: {
              benefit_id: {

                translations: {
                  title: {
                    _icontains: ite
                  }
                }
              }
            }
          }
        }
      ))

      _and.push({
        _or: _orBenefits
      })
    }

    if (options.from) {
      _and.push({
        plans: {
          premium: {
            _gte: +parseInt(<string>options.from)
          }
        }
      })
    }

    if (options.to) {
      _and.push({
        plans: {
          premium: {
            _lte: +parseInt(<string>options.to)
          }
        }
      })
    }
    return getProductsEntity({
      query: {
        filter: {_and},
        fields: [
          'id',
          'status',
          'thumbnail.id',
          'thumbnail.title',
          'thumbnail.description',
          'banner_desktop.id',
          'banner_desktop.title',
          'banner_desktop.description',
          'banner_mobile.id',
          'banner_mobile.title',
          'banner_mobile.description',
          'translations.id',
          'translations.product_id',
          'translations.languages_code',
          'translations.title',
          'translations.slug',
          'translations.intro',
          'carrier.id',
          'carrier.status',
          'carrier.url',
          'carrier.thumbnail.id',
          'carrier.thumbnail.title',
          'carrier.thumbnail.description',
          'category.id',
          'category.status',
          'category.translations.id',
          'category.translations.languages_code',
          'category.translations.title',
          'search_terms.*',
          'plans.id',
          'plans.sort',
          'plans.is_active',
          'plans.carrier',
          'plans.premium',
          'plans.sum_insured',
          'plans.type_insured_period',
          'plans.insured_period',
          'plans.translations.*',
          'plans.benefits.*',
          'plans.benefits.translations.*',
          'plans.benefits.benefit_id.id',
          'plans.benefits.benefit_id.status',
          'plans.benefits.benefit_id.sort',
          'plans.benefits.benefit_id.code',
          'plans.benefits.benefit_id.parentBenefit',
          "plans.benefits.benefit_id.translations.*"
        ],
        sort: ['sort', '-date_created'],
        limit: query && query.limit ? +parseInt(<string>query.limit) : 9,
        page: query && query.page ? +parseInt(<string>query.page) : 1,
        ...(query ?? {}),
        // @ts-ignore
        meta: 'filter_count'
      }
    })
      .then((products: any) => {
        if (products && products.data && Array.isArray(products.data)) {
          return {
            products: products.data.map((item: any) =>
              generateProductDTO(item, (options.lang as string) || 'vi-VI')
            ),
            filter_count: products.meta?.filter_count || 0,
            lang_switch: generateLangSwitch('result', options?.lang || 'vi-VI')
          }
        } else
          return {
            products: [],
            filter_count: 0
          }
      })
      .catch((err) => {
        console.log({err})
      })
  }

  const getDataSearch = async (languageCode: string) => {
    return Promise.all([
      getItems({
        collection: 'products_category',
        params: {
          fields: ['id', 'translations.title', 'translations.languages_code'],
          filter: {
            status: {
              _eq: 'published'
            },
            translations: {
              languages_code: {
                _eq: languageCode
              }
            }
          },
          limit: -1
        }
      }),
      getItems({
        collection: 'carriers',
        params: {
          fields: ['id', 'translations.title', 'translations.languages_code'],
          filter: {
            status: {
              _eq: 'published'
            },
            translations: {
              languages_code: {
                _eq: languageCode
              }
            }
          },
          limit: -1
        }
      }),
      getItems({
        collection: 'products_benefit',
        params: {
          // fields: ['id', 'translations.title', 'translations.languages_code'],
          fields: ['id', 'translations.*'],
          filter: {
            status: {
              _eq: 'published'
            },
            translations: {
              languages_code: {
                _eq: languageCode
              }
            }
          },
          limit: -1
        }
      }),
      getItems({
        collection: 'products_search_term',
        params: {
          fields: ['id', 'status', 'type', 'gte', 'lte', 'translations.*'],
          filter: {
            status: {
              _eq: 'published'
            },
            translations: {
              languages_code: {
                _eq: languageCode
              }
            }
          },
          limit: -1
        }
      })
    ])
      .then((data) => {
        let products_category = sortByTitle(data[0].map((item: any) => generateProductCategoryDTO(item, languageCode)), {key: "title"})
        let carries = sortByTitle(data[1].map((item: any) => generateCarrierDto(item, languageCode)), {key: "title"})
        let benefits = sortByTitle(generateBenefitsData(data[2].map((item: any) => generateProductPlanBenefitDTO(item, languageCode))), {key: "title"})
        // let benefits = data[2].map((item: any) => generateProductPlanBenefitDTO(item, languageCode))
        let custom_terms = groupProductsSearchTerm(
          data[3].map((ite: any) => generateProductSearchTerm(ite, languageCode))
        )

        return {
          products_category,
          carries,
          benefits,
          custom_terms,
          lang_switch: generateLangSwitch('result', languageCode)
        }
      })
      .catch((error) => {
        consola.error('Error: getDataSearch')
        consola.debug(error)
        return {
          products_category: [],
          carries: []
        }
      })
  }

  const sortByTitle = (arr: Array<any>, options: { key: string }) => {
    return arr.sort(function (a: any, b: any) {
      if (toNonAccentVietnamese(a[options.key].trim()) < toNonAccentVietnamese(b[options.key].trim())) {
        return -1;
      }
      if (toNonAccentVietnamese(a[options.key].trim()) > toNonAccentVietnamese(b[options.key].trim())) {
        return 1;
      }
      return 0;
    });
  }

  const generateBenefitsData = (data: Array<any>) => {
    let benefits = Object.values(data.reduce((acc, cur) => Object.assign(acc, {[cur.title.trim()]: cur}), {}));
    benefits = benefits.map((ite: any) => {
      const regex = "<(\"[^\"]*\"|'[^']*'|[^'\">])*>";
      const match = ite.title.trim().match(regex);
      if (match) {
        let temp = removeTags(match.input).split(';')[0]
        return {
          ...ite,
          title: temp
        }
      }
      return ite;
    })
    return benefits
  }

  const removeTags = (str: string): string => {
    if ((str === null) || (str === ''))
      return '';
    else
      str = str.toString();

    let result = str.replace(/(<([^>]+)>)/ig, '');
    return result.replaceAll(';', '; ')
  }

  const getProductsHomepage = async (languageCode: string) => {
    return getProductsDto(languageCode, {
      query: {
        filter: {
          _and: [
            {
              status: {
                _eq: 'published'
              }
            },
            {
              carrier: {
                status: {
                  _eq: 'published'
                }
              }
            },
            {
              category: {
                status: {
                  _eq: 'published'
                }
              }
            },
            {
              show_home: {
                _eq: true
              }
            }
          ]
        },
        fields: [
          'id',
          'thumbnail.id',
          'thumbnail.title',
          'thumbnail.description',
          'banner_desktop.id',
          'banner_desktop.title',
          'banner_desktop.description',
          'banner_mobile.id',
          'banner_mobile.title',
          'banner_mobile.description',
          'translations.id',
          'translations.languages_code',
          'translations.title',
          'translations.intro',
          'translations.slug',
          'carrier.thumbnail.id',
          'carrier.url',
          'carrier.thumbnail.title',
          'carrier.thumbnail.description',
          'category.id',
          'category.status',
          'category.translations.id',
          'category.translations.languages_code',
          'category.translations.title',
          // 'category.translations.slug'
        ],
        sort: ['sort', '-date_created'],
        limit: -1,
        page: 1
      }
    })
      .then((data) => {
        return data
      })
      .catch((e) => {
        consola.error('Error: getProductsHomepage')
        consola.debug(e)
      })
  }

  const getProductsAdviceForm = async (languageCode: string) => {
    return getItems({
      collection: 'products',
      params: {
        filter: {
          _and: [
            {
              status: {
                _eq: 'published'
              }
            },
            {
              plans: {
                is_active: {
                  _eq: true
                }
              }
            }
          ]
        },
        fields: [
          'id',
          'translations.languages_code',
          'translations.title',
          'plans.id',
          'plans.translations.*',
          'plans.benefits.benefit_id',
          // 'plans.benefits.benefit_id.translations.*'
        ],
        sort: ['sort', '-date_created']
      }
    }).then((data: any) =>
      Array.isArray(data) && data.length > 0
        ? data.map((ite: any) => generateProductDTO(ite, languageCode))
        : []
    ).catch((e) => {
      consola.error('Error: getProductsAdviceForm')
      consola.debug(e)
    })
  }

  const getProductsMegaMenu = async (languageCode: string) => {
    return getProductsCategoryEntity({
      query: {
        filter: {
          _and: [
            {
              status: {
                _eq: 'published'
              }
            },
            {
              mega: {
                _eq: true
              }
            },
            {
              products: {
                status: {
                  _eq: 'published'
                }
              }
            },
            {
              products: {
                mega: {
                  _eq: true
                }
              }
            }
          ]
        },
        // deep: {
        //     products: {
        //         _filter: {
        //             _and: [
        //                 {
        //                     status: {
        //                         _eq: 'published'
        //                     }
        //                 },
        //                 {
        //                     mega: {
        //                         _eq: true
        //                     }
        //                 }
        //             ]
        //         }
        //     }
        // },
        limit: -1,
        sort: ['sort', '-date_created'],
        fields: ['*', 'translations.*', 'products.*', 'products.translations.*']
      }
    })
      .then((categories) => {
        if (categories && Array.isArray(categories) && categories.length > 0) {
          return arrayToTree(
            categories
              .map((item) => generateProductCategoryDTO(item, languageCode))
              .map((item) => ({
                id: item.id,
                sort: item.sort,
                name: item.title,
                target: null,
                parent: item.parent,
                url: item.url,
                childs:
                  item.products && Array.isArray(item.products) && item.products.length > 0
                    ? item.products.map((ite: any) => ({
                      id: ite.id,
                      sort: ite.sort,
                      name: ite.title,
                      target: null,
                      url: ite.url,
                      parent: null,
                      childs: []
                    }))
                    : []
              })),
            {
              parentProperty: 'parent',
              childrenProperty: 'childs'
            }
          )
        }
      })
      .catch((e) => {
        console.log("Error getProductsCategoryEntity: ", e)
      })
  }

  const actionGetPolicies = async (languageCode: string, response: string, params: TypeParamsGetPolicies) => {

    return $fetch('/api/verify/policies', {
      method: 'POST',
      params: {
        response,
        ...params
      }
    }).then((data: any) => {
      if (data.code == 201 || data.code == 200) {
        let res = data.data.map((item: any) => ({
          ...item,
          product: generateProductDTO(item.product, languageCode)
        }))
        return res.sort(
          (objA: any, objB: any) => {
            const dateA: any = new Date(objA.policyExpiringDate);
            const dateB: any = new Date(objB.policyExpiringDate);
            return dateB - dateA
          }
        )
      } else {
        return data
      }
    }).catch(e => {
      consola.error("Error actionGetPolicies")
      consola.info(e)
    })

  }

  const actionCheckExistActivePolicies = async (languageCode: string, response: string, params: TypeParamsCheckExistActivePolicies) => {

    return $fetch('/api/verify/policies_check', {
      method: 'POST',
      params: {
        response,
        ...params
      }
    }).then((data: any) => {
      return data;
    }).catch(e => {
      consola.error("Error actionGetPolicies")
      consola.info(e)
    })

  }

  const actionFilterProductsAnalysis = async (languageCode: string, body: { [key: string]: any }, query?: Query) => {

    let queryDefault = {
      fields: [
        'id',
        'thumbnail.id',
        'thumbnail.title',
        'thumbnail.description',
        'banner_desktop.id',
        'banner_desktop.title',
        'banner_desktop.description',
        'banner_mobile.id',
        'banner_mobile.title',
        'banner_mobile.description',
        'translations.id',
        'translations.languages_code',
        'translations.title',
        'translations.intro',
        'translations.slug',
        'translations.seo_detail.*',
        'category.id',
        'category.status',
        'carrier.url',
        'category.translations.id',
        'category.translations.languages_code',
        'category.translations.title',
        'carrier.thumbnail.id',
        'carrier.thumbnail.title',
        'carrier.thumbnail.description',
        'plans.id',
        'plans.sort',
        'plans.is_active',
        'plans.carrier',
        'plans.premium',
        'plans.sum_insured',
        'plans.type_insured_period',
        'plans.insured_period',
        'plans.translations.*',
        'plans.benefits.*',
        'plans.benefits.translations.*',
        'plans.benefits.benefit_id.id',
        'plans.benefits.benefit_id.sort',
        'plans.benefits.benefit_id.code',
        'plans.benefits.benefit_id.parentBenefit',
        'plans.benefits.benefit_id.translations.*',
        'plans.benefits.benefit_id.icon.id',
        'plans.benefits.benefit_id.icon.title',
        'plans.benefits.benefit_id.icon.description',
        'plans.benefits.translations.*',
      ]
    }
    const bodyData = await parseAnalysisData(body.list)
    return $fetch('/api/verify/analysis', {
      method: 'POST',
      params: {
        ...queryDefault,
        ...(query ?? {})
      },
      body: JSON.stringify(bodyData)
    })
      .then((response: any) => {
        if (
          response &&
          response.code === 200 &&
          response.data &&
          response.data.products &&
          Array.isArray(response.data.products) &&
          response.data.products.length > 0
        ) {
          return {
            products: response.data.products
              .map((item: any) => generateProductDTO(item, languageCode))
              .map((product: ProductTypeDTO | any) => {
                return {
                  id: product.id,
                  title: product.title,
                  url: product.url,
                  intro: product.intro,
                  plans: product.plans,
                  benefits: product.benefits,
                  // benefits: product.benefits.map((item: any) => {
                  //     return {
                  //         id: item.id,
                  //         title: item.title,
                  //         value: item.data.reduce((previousValue: any, currentValue: any) => {
                  //             if (currentValue.value > previousValue) {
                  //                 return currentValue.value
                  //             }
                  //             return previousValue
                  //         }, -Infinity)
                  //     }
                  // }),
                  thumbnail: product.thumbnail,
                  carrier: {
                    id: product.carrier.id,
                    title: product.carrier.title,
                    thumbnail: product.carrier.thumbnail
                  }
                }
              }),
            total: response.data.total
          }
        } else
          return {
            products: [],
            total: 0
          }
      })
      .catch((e) => {
        consola.error('Error actionFilterProductsAnalysis: ')
        consola.warn(e)
        return {
          products: [],
          total: 0
        }
      })
  }

  const actionPostAnalysisResult = async (body: { [key: string]: any }, token: string) => {

    const detail = await parseAnswersDetail(body)
    body = {
      ...body,
      detail: detail
    }
    delete body["birthday"]
    // consola.info(body)
    return fetch(`/api/advise?response=${token}`, {
      method: 'POST',
      body: JSON.stringify(body)
    })
      .then((response: any) => {
        return response.json()
      })
      .catch((e) => {
        consola.error('Error actionPostAnalysisResult: ')
        consola.warn(e)
        return {}
      })
  }

  //
  const getProductsDto = async (
    languageCode: string,
    options?: {
      id?: string | number
      query?: Query
    }
  ): Promise<Array<ProductTypeDTO> | ProductTypeDTO> => {
    return getProductsEntity(options)
      .then((data: Array<ProductTypeEntity> | ProductTypeEntity) => {
        return Array.isArray(data)
          ? data.map((item) => generateProductDTO(item, languageCode))
          : generateProductDTO(data, languageCode)
      })
      .catch((e: any) => {
        consola.error('Error: getProductsDto')
        consola.debug(e)
        return options?.id ? {} : []
      })
  }

  const getProductsEntity = (options?: {
    id?: string | number
    query?: Query
  }): Promise<Array<ProductTypeEntity> | ProductTypeEntity | any> => {
    let data: any = {
      collection: 'products',
      params: {
        fields: ['*', 'faqs.*', 'faqs.translations.*']
      }
    }

    if (options?.query) {
      data.params = {
        ...data.params,
        ...options.query
      }
    }
    if (options?.id) {
      data['id'] = options.id.toString()

      return getItemById(data)
    }

    return getItems(data)
  }

  const getProductsCategoryEntity = (options?: {
    id?: string | number
    query?: Query
  }): Promise<Array<ProductCategoryTypeEntity>> => {
    let data: any = {
      collection: 'products_category',
      params: {
        fields: ['*', 'translations.*']
      }
    }

    if (options?.query) {
      data.params = {
        ...data.params,
        ...options.query
      }
    }

    if (options?.id) {
      data['id'] = options.id.toString()

      return getItemById(data)
    }

    return getItems(data)
  }

  const getReferencedDocuments = async (product: any, langCode: string) => {
    try {

      let terms_links = {};
      let exclusion_links = {}
      if (product.plans) {
        let maxPlan = product.plans.find(((plan: any) => plan.sum_insured === Math.max(...product.plans.map((ite: any) => ite.sum_insured))))
        let termDocs = maxPlan.documents.filter((ite: any) => ite.doc_type === 'document')
        let exclusionsDocs = maxPlan.documents.filter((ite: any) => ite.doc_type === 'exclusion_document')

        if (termDocs[0]) {
          if (langCode === 'vi-VI') {
            const regex = /[-._](VN)[-._]/gi;
            const match = termDocs[0].name.match(regex);
            if (match) {
              terms_links = {
                title: termDocs[0].name || '',
                link: `/api/verify/document?document_id=${termDocs[0].document_id}`
              }
            }
          } else {
            terms_links = {
              title: termDocs[0].name || '',
              link: `/api/verify/document?document_id=${termDocs[0].document_id}`
            }
          }
        }

        if (exclusionsDocs[0]) {
          if (langCode === 'vi-VI') {
            const regex = /[-._](VN)[-._]/gi;
            const match = exclusionsDocs[0].name.match(regex);
            if (match) {
              exclusion_links =
                {
                  title: exclusionsDocs[0].name || '',
                  link: `/api/verify/document?document_id=${exclusionsDocs[0].document_id}`
                }
            }
          } else {
            exclusion_links =
              {
                title: exclusionsDocs[0].name || '',
                link: `/api/verify/document?document_id=${exclusionsDocs[0].document_id}`
              }
          }
        }

        return {terms_links, exclusion_links}
      }
    } catch (e) {
      consola.error('Error getReferencedDocuments: ')
      consola.warn(e)
      return {}
    }
  }

  const parseAnalysisData = async (data: any) => {
    try {

      const dataAnalysis = await getAnalysisGroupsForFilter(useRoute().params.langCode).then((response) => response?.analysis_group)
      let questions: Array<any> = [];
      let output: Array<any> = [];

      dataAnalysis.map(ite => {
        questions = questions.concat(ite.questions)
      })

      Object.keys(data).map(ite => {
        const question = questions.find(ques => {
          return ques.field_name === ite
        });
        if (ite === 'income') {
          output.push({
            question: question.id,
            answer: data[ite]
          })
        } else {
          if (!Array.isArray(data[ite])) {
            output.push({
              question: question.id,
              answer: data[ite]
            })
          } else {
            data[ite].map(item => {
              output.push({
                question: question.id,
                answer: item
              })
            })
          }
          ;
        }

      })
      return {
        list: output
      }
    } catch (e) {
      consola.error('Error parseAnalysisData: ')
      console.log(e)
      consola.warn(e)
      return {}
    }
  }

  const parseAnswersDetail = async (body: any) => {
    try {
      const dataAnalysis = await getAnalysisGroupsForFilter(useRoute().params.langCode).then((response) => response?.analysis_group)
      let questions: Array<any> = [];
      let output: Array<any> = [];
      dataAnalysis.map(ite => {
        questions = questions.concat(ite.questions)
      })
      let newBody = {
        ...body,
      }
      newBody.detail.date_of_birth = body.birthday
      const data = newBody.detail;
      Object.keys(data).map(ite => {
        const question = questions.find(ques => {
          return ques.field_name === ite
        });

        if (ite === 'date_of_birth') {
          output.push({
            question, answer: {
              content: data[ite]
            }
          })
        } else {
          if (!Array.isArray(data[ite])) {
            const answer = question.answers.find((ans => data[ite] === ans.id))
            if (question && answer) {
              output.push({
                question, answer
              })
            }
          } else {
            const answer: Array<any> = [];
            data[ite].map((userAnswer => {
              let tempAnswers = question.answers.filter((ans => userAnswer === ans.id))
              answer.push(tempAnswers)
            }))
            if (question && answer) {
              answer.map(ans => {
                output.push({
                  question, answer: ans[0]
                })
              })
            }
          }
        }

      })

      let detail: string = "";
      output.map(ite => {
        detail = detail + `${ite.question.content} : ${ite.answer.content} ` + '\n'
      })
      // consola.info(detail)
      return detail
    } catch (e) {
      consola.error('Error parseAnswersDetail: ')
      consola.warn(e)
      return ""
    }
  }

  return {
    getProductsIndex,
    // getProductById,
    getProductBySlug,
    searchProducts,
    getDataSearch,
    getProductsByCategory,
    getProductsCategory,
    getProductsDto,
    getProductsHomepage,
    getProductsMegaMenu,
    actionFilterProductsAnalysis,
    actionGetPolicies,
    getProductsAdviceForm,
    getReferencedDocuments,
    actionPostAnalysisResult,
    actionCheckExistActivePolicies
  }
}
